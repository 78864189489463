import React, { Fragment } from 'react'
import {
    InfinityList,
    Loader,
    LoaderSlider,
    Section,
    SliderBanner,
    SliderBase,
    VerticalMusic,
    VerticalVideo
} from '../../components';
import {useMusicLanding} from '../../hooks';
import './style.scss';
export const MusicLanding = () => {
    const { landing, loading, slug } = useMusicLanding();
    console.log('here we are');
    return (
        <Fragment name={landing?.category}>
            <h2>
                {landing?.category}
               </h2>

            <div className="px-3 pb-3">
                <Section name={landing?.category} >
                    <div className="d-flex px-3" style={{ flexWrap: "wrap" }}>
                        {landing?.musics?.map((item, i) => {
                            return (
                                <div className="col-md-6 col-6 my-3 px-lg-4 px-0" key={i}>
                                    <VerticalMusic
                                        cover={item?.cover}
                                        track_name={item?.name}
                                        release_year={item?.info?.release_year}
                                        artist_name={item?.artistinfo?.userinfo?.name}
                                        artist_slug={item?.artistinfo?.slug}
                                        musicObject={item}
                                        playlist={landing?.musics}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Section>
            </div>
            {/*<div className="allMusic">
                <InfinityList
                    render={(landing, i) => {
                        return (
                            <div className="sizeBox my-2" key={i}>
                                <VerticalMusic
                                    cover={landing?.cover}
                                    track_name={landing?.name}
                                    release_year={landing?.info?.release_year}
                                    artist_name={landing?.artistinfo?.userinfo?.name}
                                    artist_slug={landing?.artistinfo?.slug}
                                    musicObject={landing}
                                    playlist={landing}
                                />
                            </div>
                        )
                    }}
                    url={`music_landing/${slug}`}
                />
            </div>*/}
        </Fragment>
    )
}
