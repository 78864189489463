import React from 'react';
import './privacy-style.scss';
export const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {/* Close button (X) fixed at the top */}
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>

                <h2>سیاست حفظ حریم خصوصی</h2>
                <div className="privacy-policy-text">
                    <p>
                        سیاست حفظ حریم خصوصی اپلیکیشن خراسان مدیا
                    </p>
                    <p>
                        ما در خراسان مدیا، حفظ حریم خصوصی و امنیت اطلاعات کاربران را به عنوان یکی از اولویت‌های اصلی خود در نظر می‌گیریم. این سیاست حفظ حریم خصوصی به شما کمک می‌کند تا درک بهتری از نحوه جمع‌آوری، استفاده، ذخیره‌سازی و محافظت از اطلاعات شخصی شما در اپلیکیشن خراسان مدیا داشته باشید. لطفاً این سیاست را به دقت مطالعه کنید تا با حقوق و مسئولیت‌های خود در قبال اطلاعات شخصی‌تان آشنا شوید.
                    </p>

                    <h3>1. اطلاعاتی که جمع‌آوری می‌شود</h3>
                    <p>
                        ما ممکن است اطلاعات زیر را از کاربران خود جمع‌آوری کنیم:
                    </p>
                    <ul>
                        <li><strong>اطلاعات شخصی:</strong> شامل نام، نام خانوادگی، نام کاربری و شماره تلفن که کاربران به صورت داوطلبانه در هنگام ثبت‌نام یا استفاده از اپلیکیشن ارائه می‌دهند.</li>
                        <li><strong>تصویر پروفایل:</strong> کاربران می‌توانند تصویر پروفایل خود را در اپلیکیشن آپلود کنند.</li>
                        <li><strong>تصویر کارت ملی:</strong> هنرمندانی که قصد آپلود رسانه در اپلیکیشن را دارند، ملزم به ارائه تصویر کارت ملی برای احراز هویت هستند.</li>
                        <li><strong>کوکی‌ها و فناوری‌های ردیابی:</strong> ما از کوکی‌ها و فناوری‌های مشابه برای بهبود تجربه کاربری و تحلیل رفتار کاربران استفاده می‌کنیم.</li>
                    </ul>

                    <h3>2. نحوه استفاده از اطلاعات</h3>
                    <p>
                        اطلاعات جمع‌آوری شده ممکن است برای اهداف زیر استفاده شود:
                    </p>
                    <ul>
                        <li><strong>ارائه و بهبود خدمات:</strong> استفاده از اطلاعات برای شخصی‌سازی تجربه کاربری، ارائه خدمات بهتر و توسعه ویژگی‌های جدید.</li>
                        <li><strong>احراز هویت:</strong> استفاده از تصویر کارت ملی برای تأیید هویت هنرمندان و اطمینان از صحت اطلاعات ارائه‌شده.</li>
                        <li><strong>ارتباط با کاربران:</strong> ارسال اطلاعیه‌ها، به‌روزرسانی‌ها و پاسخ به درخواست‌های پشتیبانی.</li>
                        <li><strong>امنیت:</strong> استفاده از اطلاعات برای تشخیص و جلوگیری از فعالیت‌های تقلبی یا سوءاستفاده.</li>
                    </ul>

                    <h3>3. اشتراک‌گذاری اطلاعات با اشخاص ثالث</h3>
                    <p>
                        ما ممکن است اطلاعات کاربران را در شرایط زیر با اشخاص ثالث به اشتراک بگذاریم:
                    </p>
                    <ul>
                        <li><strong>ارائه‌دهندگان خدمات:</strong> اطلاعات ممکن است با ارائه‌دهندگان خدمات شخص ثالث (مانند میزبان‌های سرور و خدمات تحلیلی) به اشتراک گذاشته شود تا عملکرد اپلیکیشن بهبود یابد.</li>
                        <li><strong>رعایت قانون:</strong> در صورت درخواست مقامات قضایی یا قانونی، ممکن است اطلاعات کاربران را افشا کنیم.</li>
                        <li><strong>حفاظت از حقوق:</strong> در صورت لزوم برای محافظت از حقوق، امنیت یا دارایی‌های خود یا دیگران، ممکن است اطلاعات را به اشتراک بگذاریم.</li>
                    </ul>

                    <h3>4. ذخیره‌سازی و محافظت از اطلاعات</h3>
                    <p>
                        ما از روش‌های امنیتی پیشرفته برای محافظت از اطلاعات کاربران استفاده می‌کنیم، از جمله:
                    </p>
                    <ul>
                        <li><strong>رمزنگاری داده‌ها:</strong> اطلاعات حساس مانند تصویر کارت ملی و شماره تلفن با استفاده از فناوری‌های رمزنگاری ایمن می‌شوند.</li>
                        <li><strong>کنترل دسترسی:</strong> دسترسی به اطلاعات شخصی تنها به کارکنان مجاز و ارائه‌دهندگان خدمات محدود می‌شود.</li>
                        <li><strong>حفظ اطلاعات:</strong> اطلاعات کاربران تنها برای مدت زمان لازم برای تحقق اهداف جمع‌آوری شده، ذخیره می‌شوند.</li>
                    </ul>

                    <h3>5. استفاده از کوکی‌ها و فناوری‌های ردیابی</h3>
                    <p>
                        ما ممکن است از کوکی‌ها (Cookies) و فناوری‌های ردیابی مشابه برای جمع‌آوری اطلاعات مربوط به فعالیت‌های کاربران استفاده کنیم. این فناوری‌ها به ما کمک می‌کنند تا تجربه کاربری بهتری ارائه دهیم و رفتار کاربران را تحلیل کنیم. کاربران می‌توانند تنظیمات مرورگر خود را تغییر دهند تا استفاده از کوکی‌ها را محدود یا غیرفعال کنند.
                    </p>

                    <h3>6. حقوق کاربران</h3>
                    <p>
                        شما به عنوان کاربر اپلیکیشن خراسان مدیا، حقوق زیر را دارید:
                    </p>
                    <ul>
                        <li><strong>دسترسی به اطلاعات:</strong> شما می‌توانید درخواست دسترسی به اطلاعات شخصی خود را داشته باشید.</li>
                        <li><strong>اصلاح اطلاعات:</strong> در صورت وجود خطا در اطلاعات، می‌توانید درخواست اصلاح آن را بدهید.</li>
                        <li><strong>حذف اطلاعات:</strong> شما می‌توانید درخواست حذف اطلاعات شخصی خود را داشته باشید، مگر اینکه نگهداری این اطلاعات برای رعایت قوانین یا اهداف قانونی ضروری باشد.</li>
                    </ul>

                    <h3>7. تغییرات در سیاست حفظ حریم خصوصی</h3>
                    <p>
                        ما ممکن است این سیاست حفظ حریم خصوصی را به‌روزرسانی کنیم. در صورت ایجاد تغییرات عمده، کاربران را از طریق اپلیکیشن یا ایمیل مطلع خواهیم کرد. ادامه استفاده از اپلیکیشن پس از اعمال تغییرات به منزله موافقت شما با سیاست جدید است.
                    </p>

                    <h3>8. تماس با ما</h3>
                    <p>
                        اگر سوالی در مورد این سیاست حفظ حریم خصوصی دارید یا می‌خواهید در مورد اطلاعات شخصی خود اقداماتی انجام دهید، لطفاً با ما از طریق اطلاعات زیر تماس بگیرید:
                    </p>
                    <ul>
                        <li>آدرس ایمیل: <a href="mailto:info@khorasanmedia.ir">info@khorasanmedia.ir</a></li>
                        <li>شماره تلفن: <div className="phone-div"><a href="tel:+989384383273">+98 938 438 3273</a></div></li>
                    </ul>

                    <p>
                        با استفاده از اپلیکیشن خراسان مدیا، شما موافقت خود را با این سیاست حفظ حریم خصوصی اعلام می‌کنید. اگر با هر بخشی از این سیاست موافق نیستید، لطفاً از استفاده از اپلیکیشن خودداری کنید.
                    </p>

                    <p><strong>تاریخ آخرین به‌روزرسانی:</strong> 1403</p>

                    <p>
                        این سیاست حفظ حریم خصوصی به شما اطمینان می‌دهد که اطلاعات شخصی شما با دقت و امنیت مدیریت می‌شود. ما متعهد به حفظ اعتماد شما و ارائه تجربه‌ای امن و شفاف هستیم.
                    </p>
                </div>
                <button onClick={onClose}>خواندم!</button>
            </div>
        </div>
    );
};