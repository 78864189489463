import React, { Fragment } from 'react'
import { useBlogs } from '../../hooks'
import {SpinnerLoading} from '../../components';
import "./style.scss";

export const Blogs = ({ seo }) => {
    const { loading, data, setQuery, ref, PaginateComponent } = useBlogs();

    return (
        <>

                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
                <script id="schema-app" type="application/Id+json">
                    {seo?.schema}
                </script>

            {loading ? (
                <div className="videopage" style={{ marginTop: "100px" }}>
                    <div className="container">
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                            <SpinnerLoading className="loaderSix p-2" count={12} />
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    ref={ref}
                    className="videopage"
                    style={{ marginTop: "0px" }}
                >
                    <div className="container">
                        <div className="magContainer row p-0">
                            {data?.map((blogs, i) => {
                                return (
                                    <div className="mag" key={blogs.id}>
                                        {/* images sections  */}
                                        <div className="imgParent">
                                            <img
                                                src={blogs.image}
                                                alt={blogs.title}
                                                className="img"
                                            />
                                        </div>
                                        {/* text sections  */}
                                        <h5 style={{ textAlign: "center", paddingTop: "10px" }}>
                                            {blogs.title}
                                        </h5>
                                        <p
                                            style={{
                                                marginRight: "10px",
                                                overflow: "hidden",
                                                whiteSpace: "pre-wrap",
                                                textOverflow: "ellipsis",
                                                marginTop: "10px",
                                                padding: "4px",
                                            }}
                                        >
                                            {blogs.description}
                                        </p>
                                        {/* buttons sections  */}
                                        <div className="readButton">
                                            <a href={`/blogs/${blogs.slug}/`}>مشاهده</a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <br />
                        <br />
                        <br />
                    </div>
                    {PaginateComponent}
                </div>
            )}
        </>
    );
};

/*export async function getServerSideProps() {
    const seo = await fetch(BaseUrlAPI + "seo?page=category&slug=music-videos").then(
        (res) => res.json()
    );

    return {
        props: {
            seo,
        },
    };
}*/


