import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { HorizontalImage, Loaderlinear } from '../../../components'
import { useSuggests } from '../../../hooks'

export const Suggests = () => {
    const { suggests, loading } = useSuggests();
    return (
        <Fragment>
            <Helmet>
                <title>پیشنهادی سایت</title>
            </Helmet>
            <div className='px-3'>
                {loading ? <Loaderlinear /> :
                    suggests?.map?.((item, i) => {
                        return (
                            <div className="col-12 col-md-6 mb-2 py-1" key={i}
                                 style={i === 0 ? { marginTop: '4rem' } : {}}>
                                <HorizontalImage
                                    musicObject={item}
                                    playlist={suggests}
                                    cover={item?.cover}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    artist_slug={item?.artistinfo?.slug}
                                    track_name={item?.name}
                                    number={i + 1}
                                    slug={item.slug}
                                    duration={item?.duration}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}
