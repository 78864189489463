import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRequest } from "./api";
import { controlHeader } from "../components";

export const useMusicLanding = () => {
    const [landing, setLanding] = useState();
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();
    const request = useRequest();
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            await request
                .send({ url: `music_landing/${slug}`, method: "GET" })
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        setLanding(response.data);
                    }
                });
        };
        getData();
    }, [slug]);
    return { landing, loading, slug };
};