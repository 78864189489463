import React, {Fragment, useState} from 'react'
import { Form } from '../../components'
import {PrivacyPolicyModal} from "../../components/views/auth/PrivacyPolicyModal";


export const Login = ({ setPage, setPhone, errors, handleSubmit, register, onRegister }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <Fragment>
            <div className='title mb-3'>
                <p className='titletext'>ورود و ثبت نام</p>
            </div>
            <Form
                submit={handleSubmit((data) => onRegister({
                    data: data,
                    url: 'login',
                    massege: "کد تایید را وارد کنید",
                    Result: () => setPage('verify')
                })
                )}
                typeData="object"
            >
                <div className='form'>
                    <div className="mb-2">
                        <div className={errors?.mobile ? "input error" : 'input'}>
                            <input
                                {...register('mobile', { required: true })}
                                name='mobile'
                                type="number"

                                onChange={({ target: { value } }) => {
                                    setPhone(value)
                                }}
                                style={{ textAlign: "center" }}
                                placeholder='شماره تلفن را وارد کنید'
                            />
                        </div>
                    </div>
                    <button className="buttonone" type="submit">ورود به اپ</button>
                    <p style={{ textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={openModal}>
                        سیاست حفظ حریم خصوصی
                    </p>

                    {/* Privacy Policy Modal */}
                    <PrivacyPolicyModal isOpen={isModalOpen} onClose={closeModal} />
                </div>
            </Form>
        </Fragment>
    )
}

