import React, { Fragment } from 'react'
import { useSingleBlog } from '../../../hooks'
import { SpinnerLoading} from '../../../components';
import { BaseUrlAPI } from "../../../assets";
import style from "./style.scss";
import {redirect, useParams, useRoutes} from "react-router-dom";
import {useNavigate} from "react-router";

export const SingleBlog = ({ seo }) => {
    const { slug } = useParams()
    const { loading, data} = useSingleBlog();
    const nav = useNavigate()
     return (
        <>

                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
                <script id="schema-app" type="application/Id+json">
                    {seo?.schema}
                </script>
            {loading ? (
                <div style={{ marginTop: "100px" }}>
                    <div className="container">
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                            <SpinnerLoading className="loaderSix p-2" count={12} />
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ marginTop: "0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="container my-5">
                                {/* Blog Header */}
                                <div className="text-center">
                                    <h1 className="fw-bold mb-3">{data.title}</h1>
                                </div>

                                {/* Blog Image */}
                                <div className="imgContainer" style={{ height: "600px" }}>
                                    <div className="imgParent">
                                        <img
                                            src={data.image}
                                            alt={data.title}
                                            className="img"
                                        />
                                    </div>
                                </div>

                                {data.heads && (
                                    <div style={{ marginTop: "20px" }}>
                                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                            بخش های مهم این مقاله:{" "}
                                        </p>
                                        {data.heads && data.heads.length > 0
                                            ? data.heads.map((head, _index) => {
                                                return (
                                                    <div className="text" key={_index}>
                                                        <i
                                                            className={"icon ti-star"}
                                                            style={{ color: "gold", marginLeft: "5px" }}
                                                        ></i>
                                                        {head}
                                                    </div>
                                                );
                                            })
                                            : ""}
                                    </div>
                                )}

                                {/* Blog Description */}
                                {/* <p className="lead mt-4 text-justify">{data.description}</p> */}

                                {/* Blog Content */}
                                <div className="  p-4 rounded  ">
                                    <p className="fs-5 text-justify">
                                        <div dangerouslySetInnerHTML={{ __html: data.content }} />
                                    </p>
                                </div>
                                <p className="text-muted" style={{ marginTop: "10px" }}>
                                    تاریخ انتشار :{" "}
                                    {new Date(data.created_at).toLocaleDateString()}
                                </p>
                                {/* Tags */}
                                {data.tags?.length > 0 && (
                                    <div className="mt-4">
                                        <h5 className="fw-bold">تگ ها:</h5>
                                        <div className="d-flex gap-2">
                                            {data.tags.map((tag, index) => (
                                                <span
                                                    key={index}
                                                    className="badge text-muted"
                                                    style={{ fontSize: "16px" }}
                                                >
                          {tag}
                        </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {/* Back Button */}
                                <div className="mt-5 text-center">
                                    <button
                                        className="btn backButton"
                                        onClick={() => nav('/blogs')}
                                    >
                                        بازگشت به لیست مقالات
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export async function getServerSideProps(context) {
    const {
        query: { slug },
    } = context;
    const seo = await fetch(BaseUrlAPI+`seo?page=blog&slug=${slug}`).then(
        (res) => res.json(),

    );
    return {

        props: { seo },
    };
}


