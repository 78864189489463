import React from 'react'
import { Artist, HorizontalImage, SliderBanner, SliderBase, VerticalMusic, VerticalVideo, Section } from '../../components/views'
import { Link } from 'react-router-dom'
import { Loader, LoaderSlider, Loaderlinear } from '../../components/commons'
import './style.scss';
import { Helmet } from 'react-helmet'
import { useHomeContext } from '../../contexts'

export const Home = () => {

  const { data, loading } = useHomeContext()

  return (
    <div className='home'>
      <Helmet>
        صفحه اصلی | خراسان مدیا
      </Helmet>
      <section className='mt-3'>
        {!loading
          ?
          <SliderBanner
            pagination={true}
            data={data?.sliders}
            renderItem={(item, i) => {
              return (
                <Link key={i} to={item?.href}>
                  <img
                    src={item?.image}
                    alt='pictuer'
                    className='image'
                    width={0}
                    height={0}
                  />
                </Link>
              )
            }}
          />
          :
          <div className='container'>
            <Loader className='loader col-12 my-2' count={1} />
          </div>
        }
      </section>
      <section className='section mt-3'>
        <div className='container'>
          <Section title="آهنگ" toMore='/music'>
            {!loading ?
              <SliderBase
                pagination={false}
                prePage={4}
                data={data?.musics}
                breakpoints={{
                  768: {
                    perPage: 3,
                  },
                  479: {
                    perPage: 3,
                  },
                  1024: {
                    perPage: 4
                  }
                }}
                renderItem={(item, i) => {
                  return (
                    <div key={i} className=' py-2'>
                      <VerticalMusic
                        cover={item?.cover}
                        track_name={item?.name}
                        release_year={item?.info?.release_year}
                        artist_name={item?.artistinfo?.userinfo?.name}
                        artist_slug={item?.artistinfo?.slug}
                        musicObject={item}
                        playlist={data?.musics}
                      />
                    </div>
                  )
                }}
              />
              :
              <LoaderSlider />
            }
          </Section>
        </div>
      </section>
      <section className='section mt-1'>
        <div className='container'>
          <Section title="موزیک ویدئو" toMore='/music-videos'>
            {!loading ?
              <SliderBase
                pagination={false}
                breakpoints={{
                  768: {
                    perPage: 3,
                  },
                  479: {
                    perPage: 2,
                  },
                  1024: {
                    perPage: 4
                  }
                }}
                prePage={4}
                data={data?.music_videos}
                renderItem={(item, i) => {
                  return (
                    <div key={i}>
                      <VerticalVideo
                        cover={item?.cover}
                        to={'/music-videos/' + item?.slug}
                        artist_name={item?.artistinfo?.userinfo?.name}
                        release_year={item?.info?.release_year}
                        toArtist={'/artist/' + item?.artistinfo?.slug}
                        name_video={item?.name}
                      />
                    </div>
                  )
                }}
              />
              :
              <LoaderSlider className='loaderTree' />
            }
          </Section>
        </div>
      </section>
      {data?.sections?.map((item, i) => {
        let href = '/';
        switch (item.rel_type) {
          case 'Video':
            href = "/landing/" + item.category_slug;
            break;
          case 'MusicVideo':
            href = "/music-landing/" + item.category_slug;
            break;
          case 'Artist':
            href = "/music/artists";
            break;
          default:
            break;
        }
        return (
          <section className='section mt-1' key={i}>
            <div className='container'>
              <Section title={item?.title} toMore={href}>
                {!loading ?
                  <SliderBase
                    pagination={false}
                    breakpoints={{
                      768: {
                        perPage: 3,
                      },
                      479: {
                        perPage: 2,
                      },
                      1024: {
                        perPage: 4
                      }
                    }}
                    prePage={4}
                    data={item?.data}
                    renderItem={(obj, i) => {
                      switch (item.rel_type) {
                        case "Video":
                          return (
                            <div key={i}>
                              <VerticalVideo
                                cover={obj?.cover}
                                name_video={obj?.name}
                                to={"/video/" + obj?.slug}
                                release_year={obj?.info?.release_year}
                              />
                            </div>
                          );
                        case "MusicVideo":
                          return (
                            <div key={i}>
                                <VerticalMusic
                                    cover={obj?.cover}
                                    track_name={obj?.name}
                                    release_year={obj?.info?.release_year}
                                    artist_name={obj?.artistinfo?.userinfo?.name}
                                    artist_slug={obj?.artistinfo?.slug}
                                    musicObject={obj}
                                    playlist={item?.data}
                                />
                            </div>
                          );
                        case "Artist":

                          return (
                            <div key={i}>
                              <Artist
                                to={`/artist/${obj?.slug}`}
                                name={obj?.userinfo?.name}
                                image={obj?.userinfo?.profile}
                              />
                            </div>
                          );
                        default:
                          return null;
                      }

                    }}
                  />
                  :
                  <LoaderSlider className='loaderTree' />
                }
              </Section>
            </div>
          </section>
        )
      })}

      <section className='section mt-1'>
        <div className='container'>
          <Section title="موارد پیشنهادی" toMore='/music/suggests'>
            {!loading ?
              <div className="d-flex px-sm-3 px-0" style={{ flexWrap: "wrap" }}>
                {data?.suggests?.map((item, i) => {
                  return (
                    <div className="col-md-6 col-12 my-2 px-sm-2 px-0" key={i}>
                      <HorizontalImage
                        musicObject={item}
                        playlist={data?.suggests}
                        cover={item?.cover}
                        artist_name={item?.artistinfo?.userinfo?.name}
                        artist_slug={item?.artistinfo?.slug}
                        track_name={item?.name}
                        number={i + 1}
                        slug={item.slug}
                        duration={item?.duration}
                      />
                    </div>
                  )
                })}
              </div>
              :
              <Loaderlinear />
            }
          </Section>
        </div>
      </section>
      <section className='section mt-1'>
        <div className='container'>
          <Section title="خوانندگان" toMore='/music/artists'>
            {!loading ?
              <SliderBase
                pagination={false}
                breakpoints={{
                  768: {
                    perPage: 3,
                  },
                  479: {
                    perPage: 2,
                  },
                  1024: {
                    perPage: 4
                  }
                }}
                prePage={4}
                data={data?.artists}
                renderItem={(item, i) => {
                  return (
                    <Artist
                      to={`/artist/${item?.slug}`}
                      name={item?.userinfo?.name}
                      image={item?.userinfo?.profile}
                    />
                  )
                }}
              />
              :
              <LoaderSlider />
            }
          </Section>
        </div>
      </section>
    </div>
  )
}
