import { useEffect, useState } from "react";
import { Form } from "../../../components";
import { useUploadfile } from "../../../hooks";
import '../style.scss'
import { toast } from "react-toastify";

const useFileManager = () => {

  const [result, setResult] = useState()

  const getDuration = ({ file, type }) => {
    const tpHHMMSS = (time) => {
      let sec_num = parseInt(time, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return hours + ':' + minutes + ':' + seconds;
    }
    var item = window.document.createElement(type);
    item.preload = 'metadata';
    item.onloadedmetadata = function () {
      window.URL.revokeObjectURL(item.src);
      setResult(tpHHMMSS(item.duration));
    }
    item.src = URL.createObjectURL(file);

  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = window.document.createElement("canvas");
      const video = window.document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve({ file: blobToFile(dataURItoBlob(canvas.toDataURL("image/png")), 'image-' + Math.random() * 1000000), url: canvas.toDataURL("image/png") });
      };
    });
  };
  const blobToFile = (theBlob, fileName) => {
    let file = new File([theBlob], fileName + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
    return file;
  }
  return {
    result,
    getDuration,
    generateVideoThumbnail
  }
}

const Input = ({ label, type, name, id, placeholder, className, state }) => {
  return (
    <div className={'col-md-4 col-sm-6 col-12 p-1 ' + className}>
      <div>
        <label htmlFor={id}>{label}</label>
        <input
          className='input'
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          style={{ direction: 'rtl' }}
          onChange={(e) => state(e.currentTarget.value)}
        />
      </div>
    </div>
  )
}

export const Uploadfile = () => {
  const { data, preview, setPreview, postData, Slug, percent } = useUploadfile();
  const { getDuration, result } = useFileManager();
  const [duration, setDuration] = useState('');

  const [fileType, setFileType] = useState(null)
  const [musicName, setMusicName] = useState(null)
  const [category, setCategory] = useState(null)
  const [year, setYear] = useState(null)
  const [writer, setWriter] = useState(null)
  const [musicMaker, setMusicMaker] = useState(null)
  const [mixMaster, setMixMaster] = useState(null)
  const [vido, setVido] = useState(null)
  const [status, setStatus] = useState(false)






  const handelSubmit = (e) => {

    if(musicName === null || musicName.trim().length < 1   ) return  toast.error('لطفا نام موزیک را وارد کنید', { theme: "colored" })
    if(category === null|| category.trim().length < 1   ) return  toast.error('لطفا دسته بندی را وارد کنید', { theme: "colored" })
    if(year === null|| year.trim().length < 1   ) return  toast.error('لطفا سال تولید را وارد کنید', { theme: "colored" })
    if(writer === null || writer.trim().length < 1  ) return  toast.error('لطفا نام تویسنده را وارد کنید', { theme: "colored" })
    if(musicMaker === null|| musicMaker.trim().length < 1   ) return  toast.error('لطفا نام آهنگ ساز را وارد کنید', { theme: "colored" })
    if(mixMaster === null || mixMaster.trim().length < 1 ) return  toast.error('لطفا نام تنظیم کننده را وارد کنید', { theme: "colored" })
    if(vido === null ) return  toast.error('لطفا فایل موزیک یا موزیک ویدیو را وارد کنید', { theme: "colored" })
    if(status === false ) return  toast.error('لطفا فایل موزیک یا موزیک ویدیو را وارد کنید', { theme: "colored" })
    if(!preview) return  toast.error('لطفا تصویر را وارد کنید', { theme: "colored" })

    postData(e)
  }




  useEffect(() => {
    setDuration(result)
  }, [result])

  return (
    <div>
      <Form
        submit={handelSubmit}
        className='uplodFile'
        loading={`<div class="d-flex align-items-center justify-content-center h-100">${percent}%</div>`}
      >
        <input type="hidden" name="duration" value={duration} />
        <input type="hidden" name="slug" value={Slug} />
        <Input
          name='name'
          label='نام موزیک'
          placeholder='نام موزیک را وارد کنید'
          type='text'
          id='name'
          state = {setMusicName}
        />
        <div className='col-md-4 col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor=''>انتخاب دسته بندی</label>
            <select name="category_id"
                    onChange={(e) => {
                      setCategory(e.target.value)
                    }}
            >
              <option disabled>انتخاب کنید</option>
              {Array.isArray(data) && data?.map((item, i) => {
                return (
                  <option key={i} value={item?.id}>{item?.name}</option>
                )
              })
              }
            </select>
          </div>
        </div>
        <div className='col-md-4 col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='type-media'> نوع فایل </label>
            <select name="type" id="type-media"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === 'music') {
                        setFileType("mp3"); // Set file type to MP3 for music
                      } else if (selectedValue === 'video') {
                        setFileType("mp4"); // Set file type to MP4 for video
                      }else {
                        setFileType("mp3");
                      }
                    }}
            >
              <option disabled>انتخاب کنید</option>
              <option value='music'>موزیک</option>
              <option value='video'>موزیک ویدئو</option>
            </select>
          </div>
        </div>
        <Input
          label='سال تولید'
          name='info[release_year]'
          id='release_year'
          placeholder='سال تولید را وارد کنید'
          type='number'
          state = {setYear}
        />
        <Input
          label='نام نویسنده'
          name='info[songwriter]'
          id='songwriter'
          placeholder='نام نویسنده را وارد کنید'
          type='text'
          state = {setWriter}
        />
        <Input
          label='نام آهنگ ساز'
          name='info[composer]'
          id='composer'
          placeholder='نام آهنگ ساز را وارد کنید'
          type='text'
          state = {setMusicMaker}
        />
        <Input
          label='نام تنظیم کننده'
          name='info[arranger]'
          id='arranger'
          placeholder='نام تنظیم کننده را وارد کنید'
          type='text'
          state = {setMixMaster}
        />

        <div className='col-12 p-1 '>
          <div>
            <label htmlFor='text-music'>{` متن موزیک (اختیاری)`}</label>
            <textarea
              className='input'
              name='text'
              id='text-music'
              placeholder={`متن موزیک را وارد نمایید (اگه می خوای تو گوگل بالا بیاد متن بنویس :)`}
              style={{ direction: 'rtl', height: 100 }}
            />
          </div>
        </div>
        <div className='col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='file-normal'>انتخاب فایل</label>
            <div className='file'>
              <p id="name-file">فایل مورد نظر را انتخاب کنید</p>
              <input
                type="file"
                name="urls[normal]"
                onChange={({ target: { files } }) => {
                    const name = window.document.getElementById('name-file');

                    if (files.length) {
                      if(files[0].size > 500000000){
                        return toast.error('فایل مورد نظر باید کمتر از 500 مگابایت باشد', { theme: "colored" })
                      }else {
                        setStatus(true)
                        setVido(files[0])
                        getDuration({ file: files[0], type: "audio" });
                        name.innerHTML = files[0].name;
                      }

                    } else {
                      name.innerHTML = 'فایل مورد نظر را انتخاب کنید';
                    }


                }}
                accept={`${fileType == "mp4" ?"video/mp4" :"audio/mp3,"}   `}
                id="file-normal"
              />
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='file-cover'>{'انتخاب تصویر فایل (حداکثر 10Mb)'}</label>
            <div className='file'>

              {preview
                ?
                <img
                  className='image'
                  src={URL.createObjectURL(preview)}
                  alt="preview"
                  priority
                />
                :
                <p>تصویر مورد نظر را انتخاب کنید</p>
              }
              <input
                onChange={(e) => setPreview(e.target.files[0])}
                type="file"
                name="cover"
                id="file-cover"
              />
            </div>
          </div>
        </div>

        <div className='submit'>
          <button type="submit" disabled={percent > 0 ? true: false}>ثبت</button>
        </div>

        {
          percent > 0 ?(
              <div style={{ width: "50%", height: "40px", backgroundColor: "#e0e0e0", borderRadius: "10px", overflow: "hidden", margin:"0 auto", textAlign:"center" }}>
                <div
                    // className={style.progressBar}
                    style={{
                      width: `${percent}%`,
                      height: "100%",
                      backgroundColor: "#4caf50",
                      textAlign: "center", // Corrected property name and value
                      color: "white",
                      fontSize: "12px",
                      lineHeight: "40px",
                      transition: "all 0.3s ease"
                    }}
                >
                  {percent}%
                </div>
              </div>

          ):null
        }
      </Form>
    </div >
  )
}
