import { useEffect, useState } from "react";
import { useRequest } from "./api";
import { useNavigate, useParams } from "react-router";
import { controlHeader } from "../components";

export const useBlogs = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { send } = useRequest();

    useEffect(() => {
        setLoading(true);
        send({ url: `blogs`, method: "GET" }).then((response) => {
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
            }
        });
    }, []);

    return {
        data,
        loading,
    };
};
export const useSingleBlog = () => {
    const { slug } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { send } = useRequest();

    useEffect(() => {
        if (!slug) return; // ✅ Moved inside useEffect to avoid breaking hook rules

        console.log("Fetching blog with slug:", slug);
        setLoading(true);

        send({ url: `/blogs/${slug}`, method: "GET" })
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data);
                }
            })
            .catch((error) => console.error("Error fetching blog:", error))
            .finally(() => setLoading(false));

    }, [slug]); // ✅ Added `send` as a dependency to avoid stale closures

    return {
        data,
        loading,
        slug,
    };
};