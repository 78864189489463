import React from 'react';
import './copyright-style.scss';
export const CopyRightModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {/* Close button (X) fixed at the top */}
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>

                <h2>© کلیه حقوق مادی و معنوی این اپلیکیشن و محتوای آن متعلق به خراسان مدیا می‌باشد.</h2>
                <div className="copy-right-text">
                    <p>
                        هرگونه استفاده، کپی‌برداری، انتشار یا بازنشر مطالب، تصاویر، ویدیوها و سایر محتوای موجود در این اپلیکیشن، تنها با کسب مجوز کتبی از خراسان مدیا مجاز است. هرگونه استفاده غیرمجاز پیگرد قانونی خواهد داشت.

                        خراسان مدیا متعهد به حفظ حقوق مالکیت فکری و معنوی است و از هرگونه سوءاستفاده از محتوای خود جلوگیری می‌کند.
                    </p>
                </div>
                <button onClick={onClose}>خواندم!</button>
            </div>
        </div>
    );
};